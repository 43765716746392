import axios from 'axios';
import { ethers } from 'ethers';
import abi from '@/abi/abi';

const GOV_TECH_ADDRESS = process.env.VUE_APP_NFT_MINISTRY_TECH_ADDRESS;
const DEVTEAM_ADDRESS = process.env.VUE_APP_NFT_DEVTEAM_ADDRESS;
const multiTransContractAddr = process.env.VUE_APP_MULTITRANS_CONTRACT_ADDRESS;
const xpdAddress = process.env.VUE_APP_XPD_CONTRACT_ADDRESS;
const account = process.env.VUE_APP_RIIVR_CONTRACT_OWNER_ADDRESS;
const contractAddrs = process.env.VUE_APP_NFT_CONTRACT_ADDRESS;
let itemValAsInt;
let RATE;
let commissionFee;
let sellerAmount;
let splitAmount;
let addressList;
let valueTransferList;
let hexValueTransferList;
const purchaseItemETH = async (token, itemID, itemVal) => {
  const url = `/api/payment/purchase/${itemID}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // call new provider
  console.log(account);
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  console.log(ethers.utils.parseUnits(itemVal, 'gwei'));
  const tx = await signer.sendTransaction({
    to: '0x508c7fcEA9CDB4A4bCD8707f34564CfE036102A6',
    gasLimit: ethers.utils.hexlify(25000),
    gasPrice: ethers.utils.hexlify(parseInt(await provider.getGasPrice(), 10)),
    value: ethers.utils.parseUnits(itemVal, 'gwei'),
  });
  const result = await tx.wait();
  const body = { tx: tx.hash };
  console.log(result);
  return axios.post(url, body, config);
};

const getTxFee = async (token, itemID) => {
  const url = `/api/payment/getTxFee/${itemID}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const response = await axios.get(url, config);
    return response.data.TxFee;
  } catch (error) {
    console.error('Error fetching transaction fee:', error);
    // Return a default value or null if an error occurs
    return null;
    // Alternatively, you can throw an error
    // throw new Error('Failed to fetch transaction fee');
  }
};

const purchaseItemXPD = async (token, itemID, itemVal, user, seller) => {
  console.log('purchaseXPD');
  const url = `/api/payment/purchase/${itemID}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const txFee = await getTxFee(token, itemID);

  await window.ethereum.request({ method: 'eth_requestAccounts' });
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();
  const multiTransContract = new ethers.Contract(multiTransContractAddr,
    abi.multiTransAbi, signer);
  const contract = new ethers.Contract(contractAddrs, abi.xpdAbi, signer);
  const txApprove = await contract.approve(multiTransContractAddr,
    ethers.utils.parseUnits(itemVal, 'gwei'));
  await txApprove.wait();
  console.log(txApprove);
  const sellerAddress = seller;
  //  checks if this has REFCODE
  if (user.refCode) {
  //    IF REF code Valid  COMMISSION = 1.5% * ItemVal
  //  TRANSFER TO Seller  98.5%   /  REF COMMISSION/3  /   DEV/3  /  GOV/3
    itemValAsInt = parseInt(itemVal, 10);
    RATE = 0.015;
    commissionFee = Math.round(itemValAsInt * RATE);
    sellerAmount = itemValAsInt - commissionFee;
    splitAmount = Math.round(commissionFee / 3);
    addressList = [sellerAddress, user.refCode, DEVTEAM_ADDRESS, GOV_TECH_ADDRESS];
    valueTransferList = [(ethers.utils.parseUnits(sellerAmount.toString(), 'gwei')),
      (ethers.utils.parseUnits(splitAmount.toString(), 'gwei')),
      (ethers.utils.parseUnits(splitAmount.toString(), 'gwei')),
      (ethers.utils.parseUnits(splitAmount.toString(), 'gwei'))];
    hexValueTransferList = valueTransferList.map((value) => value.toHexString());
    console.log('total Amount', sellerAmount + splitAmount * 3);
  } else {
  //    IF NO REF code  COMMISSION = 3% * ItemVal
  //  TRANSFER TO Seller  97%   /   DEV/3  /  GOV/3
    console.log('noref');
    itemValAsInt = parseInt(itemVal, 10);
    RATE = 0.03;
    commissionFee = Math.round(itemValAsInt * RATE);
    sellerAmount = itemValAsInt - commissionFee;
    splitAmount = Math.round(commissionFee / 2);
    addressList = [sellerAddress, DEVTEAM_ADDRESS, GOV_TECH_ADDRESS];
    valueTransferList = [(ethers.utils.parseUnits(sellerAmount.toString(), 'gwei')),
      (ethers.utils.parseUnits(splitAmount.toString(), 'gwei')),
      (ethers.utils.parseUnits(splitAmount.toString(), 'gwei'))];
    hexValueTransferList = valueTransferList.map((value) => value.toHexString());
    console.log('total Amount', sellerAmount + splitAmount * 2);
  }
  console.log(addressList, hexValueTransferList);
  // const txTrans = await multiTransContract.multiSendToken(xpdAddress,
  //   addressList, hexValueTransferList);
  const txTrans = await multiTransContract.connect(signer).multiSendToken(xpdAddress,
    addressList, hexValueTransferList, {
      value: txFee,
    });

  // const tx = await contract.transfer(account, ethers.utils.parseUnits(itemVal, 'gwei'));
  const result = await txTrans.wait();
  console.log(result);
  const body = { tx: txTrans.hash };
  return axios.post(url, body, config);
  // check if unminted
};

export default {
  purchaseItem: async (token, itemID, itemVal, currency, user, seller) => {
    if (currency === 'eth') {
      return purchaseItemETH(token, itemID, itemVal, user, seller);
    }
    if (currency === 'xpd') {
      return purchaseItemXPD(token, itemID, itemVal, user, seller);
    }
    return null;
  },
};
