<!-- Log
  version 0.2: 20230725
  Update currency views, always
  shows notation with comma
-->
<template>
  <information-panel>
    <template v-slot:header>
      <!--sales information-->
      <span class="text--secondary subtitle-1"></span>
    </template>
    <template v-slot:content>
      <p class="text--secondary body-1 mb-0">Current Price</p>
      <p class="d-flex flex-row align-center">
        <v-icon>
          {{getIcon}}
        </v-icon>
        <!--price-->
        <!--span class="text-h5 font-weight-bold mx-2 mt-auto">{{convertETH}}</span-->
        <span class="text-h5 font-weight-bold mx-2 mt-auto">{{convertETHwithColon}}</span>
        <span class="text--secondary body-1 mt-auto">{{getCurrrency}}</span>
      </p>

      <buttons-container>
        <v-btn
    v-if="!isOwner && nft.onSale"
    color="primary"
    large
    class="font-weight-bold text-capitalize"
    @click="isUserLoggedIn ? buyHandler2() : loginHandler()"
    :disabled="checkETH"
  >
    <v-icon class="mr-3">mdi-wallet</v-icon>
    {{ isUserLoggedIn ? 'Buy' : 'Login to Buy NFT' }}
  </v-btn>
        <v-btn
          color="primary"
          large
          v-show="showSaleButton"
          class="font-weight-bold text-capitalize"
          @click="sellHandler"
        >
        <v-icon class="mr-3">mdi-wallet</v-icon>
          Sell
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          v-show="false"
          class="font-weight-bold text-capitalize"
          @click="offerHandler"
        >
        <v-icon class="mr-3">mdi-tag</v-icon>
          Make Offer
        </v-btn>
      </buttons-container>
      <p class="d-flex flex-row align-center mt-2" id="endCard">
        This is special for you!
      </p>
    </template>
  </information-panel>
</template>

<script>
// import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import BigNumber from 'bignumber.js';
import purchaseAPI from '@/api/purchase';
import itemAPI from '@/api/item';
// import approve from '@/api/approve';

export default {
  name: 'NFTListingCartBlock',
  data() {
    return {
      newPrice: '',
      overlay: false,
    };
  },
  props: { // change to passing nft object
    id: {
      type: String,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    nft: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      alert: 'alert/callErrorMessage',
      login: 'user/login',
    }),
    async buyHandler2() {
      // purchaseItem: async (token, itemID, itemVal, currency)
      try {
        this.$emit('openOverlay');
        await this.login();
        this.alert('Transfering');
        const result = await purchaseAPI.purchaseItem(this.token, this.id,
          this.nft.value, this.nft.currency, this.profile, this.nft.owner.publicAddress);
        this.alert('Awaiting purchase');
        if (!result) throw new Error('Invalid API Call');
        await result;
        this.$emit('closeOverlay');
        this.$router.go();
      } catch (e) {
        console.log(e);
        this.alert('Error has occured in transaction. Please contact admin immediately.');
        this.$emit('closeOverlay');
      }
    },
    offerHandler() {
      console.log('test');
    },
    async loginHandler() {
      await this.login();
      if (this.user) {
        window.location.reload(); // Or use a Vue.js friendly approach
      } else {
        throw new Error('Login failed');
      }
    },
    async sellHandler() {
      console.log(this.nft.metadata.tokenID);
      this.$emit('openOverlay');
      await this.login();
      itemAPI.putOnSale(this.token, this.nft.metadata.tokenID, this.nft.publicID)
        .then((res) => {
          console.log(res);
          this.$emit('closeOverlay');
          // reload same page
          this.$router.go();
        }).catch((err) => {
          this.$emit('closeOverlay');
          // add alert to this part
          console.log(err); // change to alert
        });
      console.log('sell information');
    },
    netPrice() {
      let calculatedVal = BigNumber(this.newPrice);
      calculatedVal = calculatedVal.multipliedBy(1000000000); // convert to eth
      return calculatedVal.toString();
    },
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      profile: 'user/profile',
    }),

    convertETHwithColon() {
      let calculatedVal = BigNumber(this.nft.value);
      calculatedVal = calculatedVal.div(1000000000); // convert to eth
      const simplifyCostFormat = new Intl.NumberFormat(undefined, {});
      return simplifyCostFormat.format(calculatedVal).toString();
    },
    formatGweiToHex() { // format gwei to hexadecimal
      let calculatedVal = BigNumber(this.nft.value);
      calculatedVal = calculatedVal.multipliedBy(1000000000);
      const hex = `0x${calculatedVal.toString(16)}`;
      console.log(this.nft.value);
      console.log(hex);
      return hex;
    },
    isUserLoggedIn() {
      return this.profile.userID && this.profile.userID !== '';
    },
    getCurrrency() {
      let symbol = '';
      if (this.nft) symbol = this.nft.currency.toUpperCase();
      return symbol;
    },
    showSaleButton() { // show sale button
      return !this.nft.onSale && this.isOwner;
    },
    showBuyButton() {
      return this.nft.onSale && !this.isOwner;
    },
    getIcon() {
      let icon = '';
      if (this.nft.currency === 'xpd') icon = '$xpdIcon';
      if (this.nft.currency === 'eth') icon = '$ethereumIcon';
      return icon;
    },
    checkETH() {
      let disableDecision = false;
      if (this.nft.currency === 'eth') { disableDecision = true; document.getElementById('endCard').innerHTML = 'Disable for ETH currency!'; }
      return disableDecision;
    },
  },
};
</script>
